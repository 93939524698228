import React, { CSSProperties } from "react";
type Props = {
  image: string;
  width: number | string;
  description: string;
  style?: React.CSSProperties;
};
const ImageWithDescription = ({ width, image, description, style }: Props) => {
  return (
    <div
      style={{
        ...style,
        margin: "2em 0",
        textAlign: "justify",
        width,
        color: "#777",
      }}
    >
      <img
        style={{ maxWidth: width, display: "block", margin: "0 auto" }}
        src={image}
        alt={description}
      />
      <p>{description}</p>
    </div>
  );
};

export default ImageWithDescription;
