import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { setToken, showError } from "../utils";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Api from "../api";
type MyProps = {
  history: any;
};
type MyState = {
  loginLoading: boolean;
};
type FormValues = {};
export default class Login extends Component<MyProps, MyState> {
  state = { loginLoading: false };
  onFinish = async (values: FormValues) => {
    this.setState({ loginLoading: true });
    try {
      const res = await Api.post("/api/login", values);
      const { token } = res;
      setToken(token);
      message.success("Successfully logged in!");
      // message.loading(" taking you back to previous page..", 0);
      //console.log(this.props.history.location.state.from.pathname);
      setTimeout(() => {
        if (this.props.history.location.state.from.pathname === "/welcome")
          window.location.href = "/welcome";
        else {
          window.location.href = "/";
        }
      }, 1500);
    } catch (error) {
      showError("Failed to login", error);
    } finally {
      this.setState({ loginLoading: false });
    }
  };
  render() {
    return (
      <Form
        onFinish={this.onFinish}
        className="login-form"
        style={{ maxWidth: "30em" }}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please enter your email address!" },
          ]}
        >
          <Input
            placeholder="Email"
            size="large"
            prefix={<UserOutlined type="user" />}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please enter your Password!" }]}
        >
          <Input
            size="large"
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          {/* {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: true,
          })(<Checkbox>Remember me</Checkbox>)} */}

          <Button
            loading={this.state.loginLoading}
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
          <br />
          <a className="login-form-forgot" href="/forgot-password">
            Forgot Password?
          </a>
          {/* <Divider>or</Divider>

          <Link style={{ fontSize: 20 }} to={"/signup"}>
            <Button size="large" className="login-form-button">
              Register now!
            </Button>
          </Link> */}
        </Form.Item>
      </Form>
    );
  }
}

// interface Form {
//   Item:
//   props:{
//     placeholder:string;
//   }

// }
