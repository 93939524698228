import React from "react";
import Manual from "../../Components/Manual";
import { Card, Divider, Typography } from "antd";
import ImageWithDescription from "../../Components/ImageWithDescription";
const { Title } = Typography;

const BatchConfigStep = (props: { index: number; description: string }) => {
  return (
    <Card style={{ marginBottom: 16 }}>
      <img
        src={`/images/manual/batchConfig/BatchConfig-${props.index}.jpg`}
        width="90%"
        alt={props.description}
      />
      <p>{props.description}</p>
    </Card>
  );
};
const batchConfigSteps = [
  { description: "First Page, leave these settings on default" },
  {
    description: `Disable Double-tap passthrough (customers might accidentally
    activate this). Also disable Hand tracking as it's not used for
    Hero Zone`,
  },
  {
    description:
      "Add the details for your Wi-Fi network so the headsets canautomatically connect",
  },
  {
    description: "Disable Always Show Floor Bound",
  },
  {
    description:
      "Choose the correct power frequency for your region. (60Hz for US,50Hz for Europe)",
  },
  {
    description: "Enable USB debugging and choose a sleep mode duration",
  },
];

export const Focus3Setup = () => {
  return (
    <div style={{ width: "100%" }}>
      <Title>Focus 3 LBE Setup</Title>
      <Divider />
      <Manual data={data} showHeadsetSelection={false} showSideBar={true} />
    </div>
  );
};
const getBatchConfigSteps = () =>
  batchConfigSteps.map((step, index) => (
    <BatchConfigStep
      key={index}
      index={index + 1}
      description={step.description}
    />
  ));
const data = [
  {
    title: "Focus 3 LBE Setup",
    sections: [
      {
        subtitle: "Purchase LBE licenses",
        content: (
          <div style={{ width: 1000 }}>
            You will need to purchase an LBE license for each headset. Visit
            this page for more information:
            <a href="https://business.vive.com/mea-en/solutions/business-plus/vive-focus-3/">
              https://business.vive.com/mea-en/solutions/business-plus/vive-focus-3/
            </a>
          </div>
        ),
      },
      {
        subtitle: "Sign Up",
        content: (
          <ol style={{ width: 1000 }}>
            <li>
              Sign up at{" "}
              <a href="https://businessplus.vive.com/user/login">
                https://businessplus.vive.com/user/login
              </a>
            </li>
          </ol>
        ),
      },
      {
        subtitle: "Create Organization",
        content: (
          <ol style={{ width: 1000 }}>
            <li>After signing in, click on "Create new Organization"</li>
            <li>
              Complete the form. Enter "N/a" for the VAT number if it's not
              applicable.{" "}
            </li>
            <li>Click on the newly created organization.</li>
            <li>
              Enter a name for your organization. The activation code is the
              code you receive when you purchase LBE licenses.
            </li>
          </ol>
        ),
      },
      {
        subtitle: "Enroll Headsets",
        content: (
          <ol style={{ width: 1000 }}>
            <li>Download the Vive Storage Console</li>
            <li>Connect your Focus 3 headset to your PC with a USB cable. Note that the cable needs to support data transfer.</li>
            <li>Open the "Connection" tab in the Storage Console</li>
            <li>Click on Enroll</li>


          </ol>
        ),
      },
      {
        subtitle: "Batch Config File",
        content: (
          <div style={{ width: 1000 }}>
            Go to{" "}
            <a href="https://business.vive.com/dm/cota/intro">
              https://business.vive.com/dm/cota/intro
            </a>{" "}
            and create a new Batch Config file for the Focus 3
            {getBatchConfigSteps()}
            <BatchConfigStep
              index={1}
              description="First Page, leave these settings on default"
            />
            <ol>
              <li>Click on publish</li>
              <li>
                Download the newly created Batch Config file and the key file
              </li>
              <li>Place the batch config and key file on a Micro-SD card</li>
              <li>
                Insert the Micro-SD card in a headset and boot up the headset.
              </li>
              <li>
                After a short while you should see a window appear telling you
                the batch config was successfully applied
              </li>
              <li>
                Now you can remove the Micro-SD card and insert it in the next
                headset.
              </li>
            </ol>
          </div>
        ),
      },
      {
        subtitle: "Enroll headsets",
        content: (
          <ol style={{ width: 1000 }}>
            <li>Open the VBConsole on Windows</li>
            <li>Connect a headset to the PC via USB</li>
            <li>Click on "Enroll"</li>

            <li>
              Give the headset a name in the device settings tab (i.e. a color
              or number to identify it)
            </li>
            <li>Repeat this process for each headset</li>
          </ol>
        ),
      },
      {
        subtitle: "Create Map Sharing",
        content: (
          <ol style={{ width: 1000 }}>
            <li>Open the Vive manager app on your phone</li>
            <li>
              You will need to pair one headset with the Vive Manager app in
              order to create a map. You can pair it by going to the
              connectivity tab in the settings on the headset and clicking on
              "Vive Manager"
            </li>
            <li>
              Go to the Vive Manager's settings and click on the headset you
              just paired. In the settings for that headset, click on
              "Enterprise features". Then select "LBE map configuration"
              creation
            </li>
            <li>Follow the instructions in the app to create a new map</li>
            <li>
              Connect the headset to your PC via USB and open the VBConsole
            </li>
            <li>Open the device settings tab and click on export map</li>
            <li>
              Create a new group, assign the map to the group by double clicking
              on the newly created group
            </li>
            <li>
              Open the devices tab, double click on each headset and assign it
              to the group
            </li>
            <li>The map should now be applied to each headset in the group</li>
          </ol>
        ),
      },
      {
        subtitle: "Kiosk Mode Setup",
        content: (
          <ul style={{ width: 1000 }}>
            <li>Go to the Headset Settings and open the Kiosk Mode tab.</li>
            <li>Click on the first option: 'assign apps'.</li>
            <li>
              Make sure 'Single pieces of content' is selected and choose
              "Waiting Room" as the designated app.
            </li>
            <li>Keep all other settings in their default state.</li>
            <li>Press the 'Enter Kiosk Mode' button to activate Kiosk Mode.</li>
            <li>
              Optionally, you can enter a PIN code to restrict unauthorized
              users from exiting Kiosk Mode.
            </li>
            <li>
              To exit Kiosk Mode, press and hold the button on the side of the
              headset until a menu appears. Select 'Quit Kiosk Mode' from the
              available options.
              <br />
              <small>
                Note: a single click on this button will summon a different
                menu.
              </small>
            </li>
          </ul>
        ),
      },
    ],
  },
];
