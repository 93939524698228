import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import App from "./App";
import "antd/dist/antd.dark.less";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://5099f9b11e954fdb8b7461b388eba630@o466072.ingest.sentry.io/5502635",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ReferenceError: findAndReplaceDOMText is not defined",
    ],
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
