import React, { useEffect, useRef, useState } from "react";
import { ImageData, VideoData } from "./Marketing";
import { ImageCard } from "./MarketingImageCard";
import { VideoCard } from "./MarketingVideoCard";

type ImageGridProps = {
  images: ImageData[] | VideoData[];
};

export const TARGET_ROW_HEIGHT = 250;
export const MAX_ROW_WIDTH = 400;
const GAP = 10;
const ImageGrid: React.FC<ImageGridProps> = ({ images }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [rows, setRows] = useState<(ImageData | VideoData)[][]>([]);

  useEffect(() => {
    if (containerRef.current) {
      calculateImageRows();
    }
    // Recalculate rows when window is resized
    window.addEventListener("resize", calculateImageRows);
    return () => window.removeEventListener("resize", calculateImageRows);
  }, [images]);

  const calculateImageRows = () => {
    if (!containerRef.current) return;

    const containerWidth = containerRef.current.clientWidth;
    const rows: (ImageData | VideoData)[][] = [];
    let currentRow: (ImageData | VideoData)[] = [];
    let currentRowWidth = 0;

    images.forEach((image) => {
      const aspectRatio = image.width / image.height;
      const imageWidth = Math.min(
        MAX_ROW_WIDTH,
        TARGET_ROW_HEIGHT * aspectRatio
      );
      const height = imageWidth / aspectRatio;
      const newWidth =
        currentRowWidth + imageWidth + (currentRow.length > 0 ? GAP : 0);
      if (newWidth <= containerWidth) {
        currentRow.push({
          ...image,
          width: imageWidth,
          height,
        });
        currentRowWidth = newWidth;
      } else {
        rows.push(currentRow);
        currentRow = [
          {
            ...image,
            width: imageWidth,
            height,
          },
        ];
        currentRowWidth = imageWidth;
      }
    });

    if (currentRow.length > 0) {
      rows.push(currentRow);
    }

    setRows(rows);
  };

  const scaleRowImages = (
    row: (ImageData | VideoData)[],
    isLastRow: boolean
  ) => {
    if (!containerRef.current || isLastRow) return row;

    const containerWidth = containerRef.current.clientWidth;
    const totalAspectRatio = row.reduce(
      (acc, img) => acc + img.width / img.height,
      0
    );
    const rowHeight =
      (containerWidth - GAP * (row.length - 1)) / totalAspectRatio;

    return row.map((image) => ({
      ...image,
      width: rowHeight * (image.width / image.height),
      height: rowHeight,
    }));
  };

  return (
    <div
      ref={containerRef}
      style={{ display: "flex", flexDirection: "column", gap: GAP }}
    >
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} style={{ display: "flex", gap: GAP }}>
          {scaleRowImages(row, rowIndex === rows.length - 1).map(
            (data, index) =>
              isVideoData(data) ? (
                <VideoCard key={index} data={data} />
              ) : (
                <ImageCard key={index} data={data} />
              )
          )}
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;

const isVideoData = (data: ImageData | VideoData): data is VideoData => {
  return (data as VideoData).videoUrl !== undefined;
};
