import { makeAutoObservable, observable } from "mobx";
import Api from "../api";
import { UserInfo } from "../types";
import { getToken } from "../utils";

class UserStore {
  @observable userInfo: UserInfo | undefined;
  constructor() {
    makeAutoObservable(this);
    this.fetchUserInfo();
  }

  async fetchUserInfo() {
    if (!getToken()) return;
    try {
      const userInfo: UserInfo = await Api.get("/api/current_user");
      this.userInfo = userInfo;
    } catch (error) {
      console.error(error);
      // showError("Failed to get user details", error);
    }
  }
}
export const userStore = new UserStore();
