import React, { Component } from "react";
import AreaChart from "../Components/charts/AreaChart";
import Api from "../api";
import Card from "../Components/Card";
import { Spin, Tabs, DatePicker, Button } from "antd";
import GamesPlayedChart from "../Components/GamesPlayedChart";
import { showError } from "../utils";
const { TabPane } = Tabs;
export default class Dashboard extends Component {
  sessions: Session[] = [];
  state = {
    fetching: true,
    hasSessions: false,
    gamesPlayed: undefined,
    averagePerHour: undefined,
    averagePerWeekday: undefined,
    numPlayers: null,
    numGames: null,
    gamesPerHour: null,
    gamesPerDay: null,
    playersPerHour: null,
    playersPerGame: null,
    filteredSessions: [],
    loading: false,
  };
  componentDidMount() {
    this.fetchGamesPlayed();
  }

  fetchGamesPlayed = async (month?: {}) => {
    try {
      const sessions: Session[] = await Api.get("/api/stats/games_played", {
        month,
      });
      this.sessions = sessions;
      this.updateStats(sessions);
    } catch (error) {
      showError("Failed to get game stats", error);
    }
  };

  updateStats = (sessions: Session[]) => {
    if (!sessions) {
      this.setState({ fetching: false, loadding: false });
      return;
    }
    const numPlayers = sessions.reduce(
      (acc, { player_count }) => acc + player_count,
      0
    );
    const numGames = sessions.length;
    const gamesPlayed = getGamesPlayedData(sessions);
    const numberOfDays = gamesPlayed.length;
    const gamesPerDay = sessions.length / numberOfDays || 0;
    const gamesPerHour = gamesPerDay / 24;
    const averagePerHour = getAverageGamesPerHour(sessions);
    const playersPerGame = numPlayers / sessions.length || 0;
    const playersPerHour = gamesPerHour * (numPlayers / sessions.length);
    const averagePerWeekday = getAveraGamesPerWeekDay(sessions, numberOfDays);
    this.setState({
      gamesPlayed,
      averagePerHour,
      numPlayers,
      numGames,
      gamesPerHour,
      gamesPerDay,
      playersPerHour,
      playersPerGame,
      averagePerWeekday,
      fetching: false,
      hasSessions: sessions.length > 0,
      filteredSessions: sessions,
      loading: false,
    });
  };
  handlePickMonth = (month: moment.Moment | null) => {
    let filteredSessions: Session[] = this.sessions;

    if (month !== null) {
      const start = month?.startOf("month").valueOf() || 0;
      const end = month?.endOf("month").valueOf() || BigInt;
      filteredSessions = [];
      this.sessions.forEach((session) => {
        const timestamp = new Date(session.timestamp).getTime();
        if (timestamp >= start && timestamp <= end) {
          filteredSessions.push(session);
        }
      });
    }

    this.setState({ filteredSessions: [], loading: true });
    setTimeout(() => {
      this.updateStats(filteredSessions);
    }, 100);
  };
  render() {
    if (this.state.fetching) return <Spin size="large" />;

    return (
      <div style={{ width: "100%" }}>
        <div style={{ float: "right", marginBottom: "16px" }}>
          <DatePicker
            onChange={this.handlePickMonth}
            picker="month"
            style={{ marginRight: 10 }}
          />
          <Button onClick={() => this.handlePickMonth(null)}>All Time</Button>
        </div>
        {!this.state.hasSessions && <p>No games played in this period.</p>}
        <div
          style={{
            display: "inline-grid",
            gridTemplateColumns: " min-content min-content auto",
            gridGap: "3.5em",
            gridAutoRows: "minmax(50px, auto)",
            width: "100%",
          }}
        >
          <Card title="PLAYERS">{<h1>{round(this.state.numPlayers)}</h1>}</Card>

          <Card title="GAMES PLAYED">
            {<h1>{round(this.state.numGames)}</h1>}
          </Card>
          <Card title="GAMES PER DAY">
            {<h1>{round(this.state.gamesPerDay)}</h1>}
          </Card>

          {/* <Card title="PLAYERS PER HOUR">{<h1>{this.state.playersPerHour}</h1>}</Card> */}
          <Card title="PLAYERS PER GAME">
            {<h1>{round(this.state.playersPerGame)}</h1>}
          </Card>

          {/* <Card title="GAMES PER HOUR">{<h1>{this.state.gamesPerHour}</h1>}</Card> */}
          <Card style={{ gridRow: "1/4", gridColumn: "3/4", width: "100%" }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="GAMES PLAYED" key="1">
                <AreaChart
                  loading={this.state.loading}
                  interpolation="basis"
                  data={this.state.gamesPlayed}
                  allowZoom={true}
                />
              </TabPane>
              <TabPane tab="AVERAGE PER HOUR" key="2">
                <AreaChart
                  loading={this.state.loading}
                  data={this.state.averagePerHour}
                  useHours
                  fixLabelOverlap={false}
                  allowZoom={false}
                  interpolation="basis"
                  tickValues={hoursTicks}
                />
              </TabPane>
              <TabPane tab="AVERAGE PER DAY" key="3">
                <AreaChart
                  loading={this.state.loading}
                  data={this.state.averagePerWeekday}
                  allowZoom={false}
                  tickValues={weekDays}
                  interpolation="catmullRom"
                />
              </TabPane>
            </Tabs>
          </Card>
          <Card style={{ gridRow: "4/4", gridColumn: "1/4", width: "100%" }}>
            <GamesPlayedChart
              loading={this.state.loading}
              sessions={this.state.filteredSessions}
            />
          </Card>
          <svg style={{ width: 0, height: 0, position: "absolute" }}>
            <defs>
              <linearGradient
                id="strokeGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop offset="0%" stopColor="hsl(329,50%,55%)" />
                <stop offset="100%" stopColor="hsl(272, 38%, 55%)" />
              </linearGradient>
              <linearGradient
                id="fillGradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop offset="0%" stopColor="hsl(329,60%,70%)" />
                <stop offset="100%" stopColor="hsl(272, 60%, 70%)" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    );
  }
}

function getAveraGamesPerWeekDay(sessions: Session[], numberOfDays: number) {
  const gamesPlayedByDay: GamesPlayedByDate = {};
  sessions.forEach(({ timestamp }) => {
    const day = new Date(timestamp).getDay();
    if (gamesPlayedByDay[day]) {
      gamesPlayedByDay[day]++;
    } else {
      gamesPlayedByDay[day] = 1;
    }
  });
  for (let i = 0; i < 7; i++) {
    if (!gamesPlayedByDay[i]) gamesPlayedByDay[i] = 0;
  }
  for (const day in gamesPlayedByDay) {
    gamesPlayedByDay[day] /= Math.max(numberOfDays / 7, 1);
  }
  const res = [];
  for (const [x, y] of Object.entries(gamesPlayedByDay)) {
    res.push({ x: parseInt(x) + 1, y });
  }
  res.sort((a, b) => {
    return a.x - b.x;
  });

  return res;

  // res.map(({ x, y }: any) => ({
  //   x: weekDays[x],
  //   y,
  // }));
}
function getAverageGamesPerHour(sessions: Session[]) {
  const gamesPlayedByHour: GamesPlayedByDate = {};
  sessions.forEach(({ timestamp }) => {
    const hour = new Date(timestamp).getHours();
    if (gamesPlayedByHour[hour]) {
      gamesPlayedByHour[hour]++;
    } else {
      gamesPlayedByHour[hour] = 1;
    }
  });
  for (let i = 0; i < 24; i++) {
    if (!gamesPlayedByHour[i]) gamesPlayedByHour[i] = 0;
  }
  for (const hour in gamesPlayedByHour) {
    gamesPlayedByHour[hour] /= 24;
  }
  // console.log(Object.values(gamesPlayedByHour).reduce((acc, x) => acc + x, 0));
  const res = [];
  for (const [x, y] of Object.entries(gamesPlayedByHour)) {
    res.push({ x: parseInt(x), y });
  }
  res.sort((a, b) => {
    return a.x - b.x;
  });
  return res;
}
function getGamesPlayedData(sessions: Session[]) {
  const gamesPlayedByDate: GamesPlayedByDate = {};
  sessions.forEach(({ timestamp }) => {
    const date = new Date(timestamp.split("T")[0]).getTime();
    if (gamesPlayedByDate[date]) {
      gamesPlayedByDate[date]++;
    } else {
      gamesPlayedByDate[date] = 1;
    }
  });
  const gamesPlayedData: GamesPlayedData[] = [];
  let startDate = Number.MAX_VALUE;
  let endDate = 0;

  Object.keys(gamesPlayedByDate).forEach((key) => {
    const y = parseInt(key);
    if (y < startDate) startDate = y;
    else if (y > endDate) endDate = y;
  });
  const numberOfDays = (endDate - startDate) / 86400000 + 1;
  for (let i = 0; i < numberOfDays; i++) {
    if (!gamesPlayedByDate[startDate + i * 86400000])
      gamesPlayedByDate[startDate + i * 86400000] = 0;
  }
  for (const [x, y] of Object.entries(gamesPlayedByDate)) {
    gamesPlayedData.push({ x: parseInt(x), y });
  }
  gamesPlayedData.sort((a, b) => {
    return a.x - b.x;
  });
  // const startDate = gamesPlayedData[0].x;
  // const endDate = gamesPlayedData[gamesPlayedData.length - 1].x;
  return gamesPlayedData;
}
export type Session = {
  player_count: number;
  timestamp: string;
  duration: number;
  score: number;
  won_game: boolean;
  difficulty: string;
  game: string;
  level: string;
};

type GamesPlayedByDate = {
  [key: number]: number;
};

type GamesPlayedData = {
  x: number;
  y: number;
};

function round(x: number | null) {
  if (x === null) return null;
  return Math.round(x * 100) / 100;
}
const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const hoursTicks = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];
