import { Menu, Select, Typography } from "antd";
import React, { ReactNode, useEffect, useState } from "react";

const { Title } = Typography;
export type ManualData = {
  title: string;
  sections?: {
    headsetTypes?: Headset[];
    subtitle?: string;
    content: ReactNode;
  }[];
  content?: ReactNode;
  headsetTypes?: Headset[];
};
type Props = {
  data: ManualData[];
  showHeadsetSelection?: boolean;
  showSideBar?: boolean;
};
export type Headset = "Quest" | "Focus3LBE" | "Focus3" | "Pico4";
function isValidHeadset(value: string) {
  const validHeadsets: Headset[] = ["Quest", "Focus3LBE", "Focus3", "Pico4"];
  return validHeadsets.includes(value as Headset);
}
const Manual = (props: Props) => {
  const [selectedHeadset, setSelectedHeadset] = useState<Headset>("Quest");
  useEffect(() => {
    if (props.showHeadsetSelection === false) return;
    // check URL params for headset type and set it if there is one
    const urlParams = new URLSearchParams(window.location.search);
    const headset = urlParams.get("headset");
    if (headset && isValidHeadset(headset)) {
      setSelectedHeadset(headset as Headset);
    }
  }, []);
  useEffect(() => {
    if (props.showHeadsetSelection === false) return;

    // update URL params with headset type
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("headset", selectedHeadset);

    // Keep the URL fragment, I.e. #Calibration
    const fragment = window.location.hash;

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParams}${fragment}`
    );
  }, [selectedHeadset]);
  const renderSideBar = () => {
    if (props.showSideBar === false) return null;
    const menuLinks = props.data.filter(
      (x) => !x.headsetTypes || x.headsetTypes.includes(selectedHeadset)
    );
    return (
      <div
        style={{
          top: "2rem",
          position: "sticky",
          height: "auto",
          display: "table",
        }}
      >
        <Menu
          openKeys={menuLinks.map(({ title }) => title)}
          forceSubMenuRender
          mode="inline"
          style={{
            padding: 15,
            maxHeight: "80vh",
            overflowX: "hidden",
            overflowY: "auto",
            maxWidth: 500,
            width: 500,
          }}
        >
          {menuLinks.map(({ title, sections }, index) => {
            const titleLink = (
              <a
                className="reset-a"
                style={{ color: "#ddd" }}
                href={`#${title}`}
              >{`${index + 1}. ${title}`}</a>
            );

            // const titleLink = `${index + 1}. ${title}`;
            if (!sections)
              return <Menu.Item key={title}>{titleLink}</Menu.Item>;

            return (
              <Menu.SubMenu
                style={{ cursor: "auto" }}
                key={title}
                title={titleLink}
              >
                {sections
                  .filter(
                    (x) =>
                      !x.headsetTypes ||
                      x.headsetTypes.includes(selectedHeadset)
                  )
                  .map(({ subtitle }) => {
                    return (
                      <Menu.Item key={subtitle}>
                        <a
                          style={{ fontSize: 14, fontWeight: 400 }}
                          href={`#${subtitle}`}
                        >{`${subtitle}`}</a>
                      </Menu.Item>
                    );
                  })}
              </Menu.SubMenu>
            );
          })}
        </Menu>
      </div>
    );
  };
  const renderContent = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "80%" }} id="content">
          {props.data
            .filter(
              (x) => !x.headsetTypes || x.headsetTypes.includes(selectedHeadset)
            )
            .map(({ sections, content, title }) => (
              <section key={title + "_section"} id={title}>
                <Title level={2}>{title}</Title>

                {content
                  ? content
                  : sections &&
                    sections
                      .filter(
                        (x) =>
                          !x.headsetTypes ||
                          x.headsetTypes.includes(selectedHeadset)
                      )
                      .map(({ content, subtitle }) => (
                        <section key={subtitle + "_section"} id={subtitle}>
                          <Title level={3}>{subtitle}</Title>
                          <br />
                          {content}
                        </section>
                      ))}
              </section>
            ))}
        </div>
      </div>
    );
  };
  return (
    <>
      {props.showHeadsetSelection !== false && (
        <HeadsetSelect onChange={setSelectedHeadset} value={selectedHeadset} />
      )}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(10rem, auto))",
          gridGap: "1rem",
          width: "80%",
          marginBottom: "10em",
        }}
      >
        {renderSideBar()}

        <br />
        {renderContent()}
      </div>
    </>
  );
};
export default Manual;
type HeadsetSelectProps = {
  onChange: (value: Headset) => void;
  value: Headset;
};
const HeadsetSelect = (props: HeadsetSelectProps) => {
  return (
    <Select
      size="large"
      style={{ width: 200, right: 20, position: "fixed" }}
      onChange={props.onChange}
      value={props.value}
    >
      <Select.Option key={"Quest"}>
        {" "}
        <HeadsetLogo headset="Quest" /> Meta Quest 2 or 3
      </Select.Option>
      <Select.Option key={"Focus3LBE"}>
        <HeadsetLogo headset="Focus3LBE" />
        Focus 3 LBE
      </Select.Option>
      <Select.Option key={"Focus3"}>
        <HeadsetLogo headset="Focus3" /> Focus 3 Non-LBE
      </Select.Option>
      <Select.Option key={"Pico4"}>
        {" "}
        <HeadsetLogo headset="Pico4" /> Pico 4
      </Select.Option>
    </Select>
  );
};

const HeadsetLogo = (props: { headset: Headset }) => {
  let logo;
  switch (props.headset) {
    case "Quest":
      logo = "/images/logos/MetaLogo.png";
      break;
    case "Focus3LBE":
    case "Focus3":
      logo = "/images/logos/ViveLogo.png";
      break;
    case "Pico4":
      logo = "/images/logos/PicoLogo.png";
      break;
  }
  if (!logo) return null;

  return <img style={{ width: 24 }} src={logo} />;
};
