import React from "react";
import { Button, Card, Menu, Typography } from "antd";
import { DownloadPage } from "./Download";
import { MailOutlined } from "@ant-design/icons";
const { Title } = Typography;
export const Welcome = () => {
  const renderMenu = () => {
    return (
      <Menu
        // mode="inline"
        style={{
          padding: 15,
          overflowX: "hidden",
          overflowY: "auto",
          width: 300,
          height: "fit-content",
        }}
      >
        {[
          "Download",
          "Getting Started",
          "Useful Links",
          "Support",
          "Discord",
        ].map((id) => (
          <Menu.Item key={id}>
            <a href={"#" + id}>{id}</a>
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, auto))",
        gridGap: "1rem",
        width: "80%",
        marginBottom: "30em",
      }}
    >
      {renderMenu()}
      <Card>
        <section id="Download">
          <DownloadPage hideFrame />
        </section>
        <section id="Getting Started">
          <Title level={3}>Getting Started</Title>
          Please refer to our Quick Start Guide to get started with Hero Zone:
          <br />
          <br />
          <a href="/quick-start" rel="noopener noreferrer" target="_blank">
            <Button type="primary">Open Quick Start Guide</Button>
          </a>
        </section>
        <section id="Useful Links">
          <Title level={3}>Useful Links</Title>
          <ul>
            <li>
              <a href="/account" rel="noopener noreferrer" target="_blank">
                Account Page
              </a>
              <br />
              Purchase credits, update your billing information, and view your
              transaction history
            </li>
            <li>
              <a href="/manual" rel="noopener noreferrer" target="_blank">
                Manual
              </a>
              <br />
              Find additional information in our complete manual
            </li>
            <li>
              <a href="/" rel="noopener noreferrer" target="_blank">
                Personal Dashboard
              </a>
              <br />
              View statistics and analytics for your Hero Zone location
            </li>
            <li>
              <a
                href="https://drive.google.com/drive/folders/1S2q2Cd_-wMGqYf0Vi_ySBzbcmAj26NRM?usp=sharing"
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                Marketing Materials
              </a>
              <br />
              Download posters,trailers and other marketing materials to promote
              your Hero Zone location
            </li>
          </ul>
        </section>

        <section id="Support">
          <Title level={3}>Support</Title>
          If you require any further assistance, please contact our support
          team:
          <br />
          <br />
          <ul>
            <li>
              <img
                style={{ width: 18, imageRendering: "crisp-edges" }}
                src="images\logos\Whatsapp.svg"
                alt="Whatsapp Logo"
              />{" "}
              0032 468 21 87 10 (WhatsApp)
            </li>
            <li>
              <MailOutlined style={{ fontSize: 18 }} /> support@herozonevr.com
            </li>
          </ul>
        </section>
        <section id="Discord">
          <Title level={3}>Community Discord</Title>
          <span>
            Be sure to join our Discord server! It’s your chance to connect with
            other Hero Zone operators. <br />
            Discuss the perfect play area, best practices, learn, teach, share,
            and have fun! <br /> Plus, it’s a direct line of contact with the
            Hero Zone team. <br />
            Be the first to receive important updates on our latest developments
            and pitch in on improvements.
          </span>
          <br />
          Join by clicking the following link:
          <br />
          <br />
          <a
            href="https://discord.gg/hJaW2MGSma"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              style={{ width: 200, imageRendering: "crisp-edges" }}
              src="images\logos\Discord.svg"
              alt="Discord Logo"
            />
            <br />
            https://discord.gg/hJaW2MGSma
          </a>
        </section>
      </Card>
    </div>
  );
};
