import { loadStripe } from "@stripe/stripe-js";
import { Button, Card, Result, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Api from "../../api";
import { getUserId, showError } from "../../utils";
import { observer } from "mobx-react";
import { userStore } from "../../stores/UserStore";
import { BillingInformationForm } from "../account/BillingInformationForm";
const { Title } = Typography;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
export const SubscriptionCheckout = observer(() => {
  const [hasAddress, setHasAddress] = useState<boolean>();
  useEffect(() => {
    checkIfAddressIsSet();
  }, []);

  const createCheckout = async () => {
    const stripe = await stripePromise;
    if (!stripe) throw new Error("Failed to load stripe");
    const session = await Api.post(
      "/api/payments/create-subscription-checkout",
      { user_id: getUserId() }
    );
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      showError("Failed to create checkout session", result.error);
    }
  };

  const checkIfAddressIsSet = async () => {
    try {
      const userId = getUserId();
      const values = await Api.get(`/api/users/${userId}/billing_info`);
      setHasAddress(!!values.address_line_1); //don't check every variable because if one of them is set, all the required variables should be set.
    } catch (error) {
      showError("Failed to get current billing information", error);
    }
  };
  if (!userStore.userInfo || hasAddress === undefined) return <Spin />;
  if (userStore.userInfo.licenseType === "credits")
    return (
      <Result
        status="error"
        title="Invalid license type"
        subTitle="This account can only buy credits."
        extra={
          <a href="/credits">
            <Button>Purchase Credits</Button>
          </a>
        }
      />
    );
  if (userStore.userInfo.is_subscription_valid) {
    return (
      <Result
        status="error"
        title="Already Subscribed"
        subTitle="This account already has a valid subscription. Please contact us if you need assistance."
        extra={
          <a href="/account">
            <Button>Back to your account</Button>
          </a>
        }
      />
    );
  }
  if (!hasAddress) {
    return (
      <div>
        <Result
          status="warning"
          title="Update Billing Information"
          subTitle="Please update your billing information before setting up your subscription."
        />
        <BillingInformationForm onSuccess={checkIfAddressIsSet} />
      </div>
    );
  }
  return (
    <Card>
      <Title>Hero Zone subscription</Title>
      Billing: Monthly
      <br /> Price: €899
      <br />
      <br />
      <Button type="primary" onClick={createCheckout}>
        Subscribe
      </Button>
    </Card>
  );
});
