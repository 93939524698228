/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Image, Typography } from "antd";

const { Title } = Typography;
export const MultipleInstances = () => {
  return (
    <div style={{ paddingBottom: 800, maxWidth: 800 }}>
      <Title level={1}>Multiple Hero Zone Arenas</Title>
      <section>
        <Title level={2}>Hero Zone Account Requirements</Title>
        <p>You can use the same account for multiple arenas provided: </p>
        <ul>
          <li>
            All of the arenas are located in the same physical location (same
            building)
          </li>
          <li>You use credits and not the monthly or yearly license</li>
        </ul>

        <p>Contact us if you want to create extra accounts</p>
      </section>
      <section>
        <Title level={3}>Other Requirements</Title>
        <p>
          The main issue you need to prevent is headsets from one arena
          connecting to the server PC of another arena.
        </p>
        <p>
          By default, headsets will connect to the last known IP address. If
          this fails, they will search for an active session on the local
          network. The headset will remember this IP address and always try to
          connect to it first.
        </p>
        <p>
          You will need a separate PC for each arena, it's not possible to run
          multiple launchers on the same PC.
        </p>
        <p>
          It's recommended to always use a wired connection for the server PC
        </p>
      </section>
      <section>
        <p>
          There are 2 options to ensure headsets connect to the correct PC:
          <ul>
            <li>
              <Title level={4}>Separate Router for Each Arena</Title>
              <p>
                This is the easiest option. Ensure each router has a different
                subnet, otherwise headsets can still connect to the wrong
                router!
              </p>

              <p>
                The routers should be set up in router mode and the ethernet
                cable supplying an internet connection should be plugged into
                the WAN port of the router.
              </p>

              <p>
                Note: Headsets may initially connect to the wrong server if they
                still have the IP address stored on the headset. See "Switching
                headsets between arenas" below
              </p>
              <ImageWithDescription
                width={500}
                src="images\manual\2routers.jpg"
                description="Example network setup diagram"
              />
            </li>
            <br />
            <li>
              <Title level={4}>Use "Fixed IP Address for Server" Setting</Title>
              <p>
                Enabling this setting in the launcher ensures headsets will only
                try to connect to the IP address stored on the headset.
              </p>
              <p>
                It's important that this IP address is correct, otherwise the
                headsets won't be able to connect at all. Each PC should be
                configured with a static IP address (set in the router) for this
                to work properly.
              </p>
              <p>
                If you want to switch a headset from 1 pc to another, install
                the games with the launcher you wish to use.
              </p>

              <ImageWithDescription
                width={500}
                src="images/manual/useFixedIP.jpg"
                description="use Fixed IP setting"
              />

              <p>
                There may be network lag/jitter if you connect too many headsets
                to a single wifi router. You can use a separate wifi access
                point for each arena to avoid this issue.
              </p>
            </li>
          </ul>
        </p>
      </section>

      <section>
        <Title level={2}>Switching headsets between arenas</Title>
        The easiest way to switch headsets between arenas is to use the
        launcher's Headset Tools. Open the Headset Tools tab in the launcher
        settings, connect your headset and click on Update Headset Config.
        <br />
        <br />
        <ImageWithDescription
          width={500}
          src="images\manual\updateConfig.jpg"
          description="Update Headset Config"
        />
      </section>
      <section>
        <Title level={2}>Diagnosing problems</Title>
        <p>
          You can use the Diagnose tab in the launcher settings to automatically
          check for common problems. If you are still having issues, please take
          a screenshot of the results on this page and send it to our support
          email (support@herozonevr.com).
        </p>
        <ImageWithDescription
          width={500}
          src="images\manual\diagnose.jpg"
          description="Diagnose tab"
        />
      </section>
    </div>
  );
};
type ImageWithDescriptionProps = {
  src: string;
  width: number | string;
  description: string;
  style?: React.CSSProperties;
};
const ImageWithDescription = ({
  width,
  src,
  description,
  style,
}: ImageWithDescriptionProps) => {
  return (
    <div
      style={{
        ...style,
        margin: "2em 0",
        textAlign: "justify",
        width,
        color: "#777",
      }}
    >
      <Image
        preview={true}
        width={width}
        style={{ display: "block", margin: "0 auto" }}
        src={src}
        alt={description}
      />
      <p>{description}</p>
    </div>
  );
};
