import axios from "axios";
import { getToken } from "./utils";

export default class Api {
  static async get(url: string, params?: {}, options?: {}) {
    const res = await axios.get(url, {
      ...options,
      params,
      headers: { "x-access-token": getToken() },
    });

    return res.data;
  }

  static async post(url: string, data: {}, options?: {}) {
    const res = await axios.post(url, data, {
      ...options,
      headers: { "x-access-token": getToken() },
    });
    return res.data;
  }

  static async patch(url: string, data: {}, options?: {}) {
    const res = await axios.patch(url, data, {
      ...options,
      headers: { "x-access-token": getToken() },
    });
    return res.data;
  }
  static async put(url: string, data: {}, options?: {}) {
    const res = await axios.put(url, data, {
      ...options,
      headers: { "x-access-token": getToken() },
    });
    return res.data;
  }
}
