import { Card } from "antd";
import { VideoData } from "./Marketing";

type VideoCardProps = {
  data: VideoData;
};

export const VideoCard = ({ data }: VideoCardProps) => {
  return (
    <div
      style={{
        backgroundColor: "#00000069",
        borderRadius: 6,
        width: data.width,
        height: data.height,
        padding: 10,
        paddingBottom: 50,
        color: "#ffffff7d",
        fontSize: 12,
        boxShadow: "2px 2px 5px 0px #0000003d",
        border: "1px solid #0000003d",
      }}
    >
      <video
        src={data.videoUrl}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
        controls
        preload="metadata"
      />
      {data.videoUrl.split("/").pop()?.split(".")[0]}
    </div>
  );
};
