import React, { useState } from "react";
import { Button, Card, Image, Typography } from "antd";
const { Title } = Typography;
import { CloudDownloadOutlined } from "@ant-design/icons";
type DownloadPageProps = {
  hideFrame?: boolean;
};
export const DownloadPage = (props: DownloadPageProps) => {
  const [downloadStarted, setDownloadStarted] = useState(false);
  const downloadLauncher = () => {
    const a = document.createElement("a");
    a.href = "https://app.herozonevr.com/latest";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDownloadStarted(true);
    setTimeout(() => {
      setDownloadStarted(false);
    }, 3000);
  };
  const renderButton = () => {
    const buttonSize = props.hideFrame ? 16 : 21;
    return (
      <Button
        disabled={downloadStarted}
        loading={downloadStarted}
        icon={<CloudDownloadOutlined style={{ fontSize: 18 }} />}
        style={{
          fontSize: buttonSize,
          padding: buttonSize,
          paddingBottom: buttonSize,
          marginTop: buttonSize - 11,
          height: "auto",
          borderRadius: 10,
          boxShadow: "2px 2px 8px 1px rgba(0, 0, 100,0.3)",
          width: 360,
        }}
        size={props.hideFrame ? "small" : "large"}
        type="primary"
        onClick={downloadLauncher}
      >
        {downloadStarted
          ? "Download In Progress"
          : "Download Hero Zone Launcher"}
      </Button>
    );
  };
  return (
    <Card
      style={
        props.hideFrame
          ? {
              background: "none",
              border: "none",
            }
          : {}
      }
    >
      <div
        style={{
          padding: props.hideFrame ? 0 : 30,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ margin: props.hideFrame ? 0 : 40, marginTop: 40 }}>
          <Title level={1}> Get Started With Hero Zone</Title>
          <p style={{ fontSize: 18 }}>
            Dive Into the Ultimate VR Gaming Experience
          </p>
          {renderButton()}
        </div>

        <Image
          src="/images/launcher.jpg"
          preview={false}
          style={{
            userSelect: "none",
            maxWidth: 600,
            margin: 20,
            border: "2px solid #000000a6",
            boxShadow: "3px 3px 10px 1px #00000054",
          }}
        />
        <br />
        <br />
      </div>
    </Card>
  );
};
