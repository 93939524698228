import React, { useEffect, useState } from "react";
import {
  Spin,
  Button,
  Typography,
  Card,
  Input,
  Modal,
  message,
  Form,
  Divider,
} from "antd";
import { BillingInformationForm } from "./BillingInformationForm";
import { Subscription } from "./Subscription";
import { userStore } from "../../stores/UserStore";
import { observer } from "mobx-react";
import { SessionOverview } from "./SessionOverview";
import { OrderOverview } from "./OrderOverview";
import Api from "../../api";
import { showError } from "../../utils";

const { Title } = Typography;

export const Account = observer(() => {
  const { userInfo } = userStore;
  const renderCreditsInfo = () => {
    if (userInfo?.licenseType !== "credits") return;
    return (
      <Card>
        <Title level={1}>Credits</Title>

        <Title level={3}>Credits Available: </Title>
        {userInfo?.credits}
        <br />
        <br />
        <a href="/credits">
          <Button size="large" type="primary">
            Buy Credits
          </Button>
        </a>
      </Card>
    );
  };

  if (!userInfo) return <Spin />;
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Title>Account</Title>
      <Card>
        <ChangePassword userId={userInfo.user_id} />
      </Card>
      <br />

      {renderCreditsInfo()}
      <Subscription />
      <br />
      <Card>
        <ReferralCode userId={userInfo.user_id} />
        <br />
        <a
          href="https://drive.google.com/drive/folders/1S2q2Cd_-wMGqYf0Vi_ySBzbcmAj26NRM?usp=sharing"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>Get Marketing Materials</Button>
        </a>
      </Card>
      <br />

      <Card>
        <BillingInformationForm />
      </Card>
      <br />

      <SessionOverview />
      <br />
      <OrderOverview />
    </div>
  );
});

const ReferralCode = (props: { userId: number }) => {
  const [code, setCode] = useState("");
  useEffect(() => {
    const fetchReferralCode = async () => {
      try {
        const { referral_code } = await Api.get(
          `/api/users/${props.userId}/referral_code`
        );
        setCode(referral_code);
      } catch (error) {
        console.error("failed to get referral code" + error);
      }
    };
    fetchReferralCode();
  }, []);
  return (
    <div>
      <Title level={5}>Referral Code: </Title>
      {code}
    </div>
  );
};

const ChangePassword = (props: { userId: number }) => {
  const [showModal, setShowModal] = useState(false);
  const [pendingChange, setPendingChange] = useState(false);
  const onFinish = async (values: any) => {
    setPendingChange(true);
    try {
      await Api.put(`/api/users/${props.userId}/password`, {
        newPassword: values.newPassword,
      });
      message.success("Password changed successfully");
    } catch (error) {
      showError("failed to change password", error);
    }
    setPendingChange(false);
    setShowModal(false);
  };
  return (
    <div>
      <Button onClick={() => setShowModal(true)}>Change Password</Button>
      <Modal
        visible={showModal}
        title="Change Password"
        closable
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <Form onFinish={onFinish}>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please enter a new password",
              },
              {
                min: 6,
                message: "Password must be at least 6 characters",
              },
            ]}
          >
            <Input type={"password"} placeholder="Enter new password..." />
          </Form.Item>
          <Divider />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ marginRight: 6 }}
              type="primary"
              danger
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button loading={pendingChange} type="primary" htmlType="submit">
              Confirm
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
