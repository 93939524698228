import React, { Component, useState } from "react";
import { Button, Tooltip, message, Input, Spin } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import Api from "../api";
import * as Sentry from "@sentry/react";
import xmlParser from "fast-xml-parser";
type GameUrl = {
  name: string;
  displayName: string;
  apkHash?: string;
  obbHash?: string;
};
const baseUrl = "https://herozone-ofb.fra1.digitaloceanspaces.com/";

const URLS: GameUrl[] = [
  {
    displayName: "Waiting Room",
    name: "WaitingRoom",
  },
];

export default class OculusBusinessSetup extends Component {
  state = {
    version: null,
  };

  componentDidMount() {
    this.fetchGameVersion();
  }

  fetchGameVersion = async () => {
    try {
      // const version = await Api.get(
      //   "https://herozone.fra1.digitaloceanspaces.com/HeroZone/.version"
      // );
      const version = "2.3.1"; // TEMPORARY
      // this.fetchHashes(version);
      this.setState({ version });
    } catch (error) {
      message.error("Failed to get version. Please contact support.");
      console.error(error);
      Sentry.captureException(error);
    }
  };

  fetchHashes = async (version: string) => {
    try {
      const xml = await Api.get(baseUrl);
      const parsedBucketData = xmlParser.parse(xml);
      const files: { Key: string; ETag: string }[] =
        parsedBucketData.ListBucketResult.Contents;
      console.log(files);
      files.forEach(({ Key, ETag }) => {
        const hash = ETag.split("&quot;")[1];
        URLS.forEach(({ name }, index) => {
          if (Key === `${name}-Android-Shipping-arm64-es2-v${version}.apk`) {
            URLS[index].apkHash = hash;
          } else if (Key === `main.1.com.Pixnami.${name}-v${version}.obb`) {
            URLS[index].obbHash = hash;
          }
        });
      });
      this.forceUpdate();
    } catch (error) {
      message.error("Failed to get hashes. Please contact support.");
      console.error(error);
      Sentry.captureException(error);
    }
  };
  renderLinks = () => {
    const { version } = this.state;
    return (
      <div
        style={{
          position: "sticky",
          alignSelf: "flex-start",
          maxHeight: "80vh",
          overflow: "auto",
          top: 0,
          padding: 15,
        }}
      >
        {URLS.map(({ name, displayName, apkHash, obbHash }) => {
          const apk = `${baseUrl}${name}-Android-Shipping-arm64-es2-v${version}.apk`;
          const obb = `${baseUrl}main.1.com.Pixnami.${name}-v${version}.obb`;
          return (
            <div key={name}>
              <br />
              <h3>{displayName}</h3>
              <CopyURL url={displayName} label="App Name" />

              <CopyURL url={apk} label="App Binary URI" />
              {/* <CopyURL url={apkHash || ""} label="Hash" /> */}
              <CopyURL
                url={`main.1.com.Pixnami.${name}.obb`}
                label="Resource FileName"
              />
              <CopyURL url={obb} label="Resource URI" />
              {/* <CopyURL url={obbHash || ""} label="Resource Hash" /> */}
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    if (!this.state.version) return <Spin />;
    return (
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <div style={{ flexDirection: "column" }}> {instructions} </div>

        {this.renderLinks()}
      </div>
    );
  }
}

const CopyURL: React.FC<{ url: string; label: string }> = ({ url, label }) => {
  const [isCopied, setIscopied] = useState(false);
  function copyToClipboard() {
    navigator.clipboard.writeText(url);
    message.success("Link copied!");
    setIscopied(true);
  }

  return (
    <div style={{ display: "flex", width: 450 }}>
      <span style={{ margin: 10, width: 200 }}>{label}</span>
      <Input.Search
        onSearch={copyToClipboard}
        value={url}
        enterButton={
          <Tooltip title="Copy URL">{isCopied ? "Copied" : "Copy"}</Tooltip>
        }
        prefix={<LinkOutlined />}
      />
    </div>
  );
};
const imageStyle = { width: 700, margin: 15 };
const instructions = (
  <div style={{ flexDirection: "column" }}>
    <h1>Installing Hero Zone games with Oculus for Business</h1>
    <ul>
      <li>
        Navigate to your Oculus for business device management page.
        (your_workspace_name.workplace.com/device_management/)
      </li>
      <li>
        <h3>Create the Waiting Room app</h3>
      </li>
      <ol>
        <li>
          Open your App Library by clicking on "Apps" in the top navigation bar.
        </li>
        <li>
          Click on the <b>Add App</b> button.
        </li>
        <img style={imageStyle} alt="step 1" src="/images/ofbSteps/step1.jpg" />
        <li>
          Enter the <b>App Name</b> ("Waiting Room").
        </li>
        <li>
          Copy the APK URI from this page and paste it under the{" "}
          <b>App Binary URI</b>.
        </li>
        <li>
          Copy the APK hash and paste it in the <b>Hash</b> field.
        </li>
        <li>Toggle Advanced Configurations.</li>
        <img style={imageStyle} alt="step 2" src="/images/ofbSteps/step2.jpg" />
        <li>
          Click on <b>Add Resource File.</b>
        </li>

        <li>
          Paste the resource <b>Filename</b>, <b>URI</b> and <b>Hash</b>.
        </li>
        <li>Click on Add resource file.</li>
        <img style={imageStyle} alt="step 3" src="/images/ofbSteps/step3.jpg" />
        <li>
          Finally, Click on <b>Create</b>.
        </li>
      </ol>
      <li>
        <h3>Create a group</h3>
      </li>
      <ol>
        <li>
          Go back to the main page of the device management, by opening the{" "}
          <b>Device Fleet </b> tab.
        </li>
        <li>
          Click on the <b>Group Devices</b> button in the top right corner.
        </li>
        <li>
          Click on <b>Create Group</b>.
        </li>
        <li>
          Device Type: <b>Oculus Quest</b> Group name:<b>Hero Zone</b>.
        </li>

        <li>Click on the newly created group and open the Settings tab.</li>
        <li>
          Turn on the <b>USB Debugging</b> option. Don't forget to click on the
          save button below!
        </li>
        <img style={imageStyle} alt="step 4" src="/images/ofbSteps/step4.jpg" />
      </ol>
      <li>
        <h3>Add app to your devices</h3>
        <ol>
          <li>
            Open the <b>Apps</b> tab in the Hero Zone group.
          </li>
          <li>
            Click on the <b>Add</b> button.
          </li>
          <li>Add the Waiting room app.</li>
          <li>
            <b>Optional:</b>Enable kiosk mode and set the default Application to
            the Waiting Room.
          </li>
          <img
            style={imageStyle}
            alt="step 5"
            src="/images/ofbSteps/step5.jpg"
          />
        </ol>
      </li>
      Now you need to wait for your devices to download the waiting room.
      <br />
      Oculus recommends adding/updating apps overnight so you can be sure they
      will be downloaded by the morning.
      <br /> To speed up this process you can restart each headset.
      <li>
        Don't forget to install the rest of the games through our launcher. When
        installing the games, make sure "Use Oculus For Business" is checked.
      </li>
      <img
        style={imageStyle}
        alt="Use Oculus for business"
        src="/images/ofbSteps/useOFB.jpg"
      />
    </ul>
    <h1>Updating The Waiting Room</h1>
    It's essential that you update the games in the launcher at the same time as
    updating the Waiting Room app on Oculus for Business. <br /> We{" "}
    <b>highly recommend doing this overnight</b> to prevent any downtime.
    <br />
    <b>The Waiting Room will not work</b> or will malfunction if it's not on the
    same version as the Waiting Room on your server PC.
    <br /> You can update the Waiting Room on windows by opening the settings
    menu.
    <br /> If there's an update available you'll see a message saying "Game
    update available." Click on the update games button to download the update.
    <h3>Updating the app in Oculus for Business</h3>
    <ol>
      <li>Navigate to your device management page and open the Apps tab.</li>
      <li>
        Click on the 3 dots on the right of the Waiting Room app to open its
        settings.
      </li>
      <li>
        Copy the APK URI from this page. If there was an update the version
        number in the URI will be different.
      </li>
      <li>Paste it in the App Binary URI field.</li>
      <li>Copy and paste the APK Hash.</li>
      <li>Click on the pencil icon next to the resource file.</li>
      <li>
        Copy and paste the resource URI and Hash. The filename should remain the
        same.
      </li>
      <li>Click on Update</li>
    </ol>
    <br />
    <br />
  </div>
);
