import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import Axios from "../api";
import { showError } from "../utils";

type MyProps = {
  history: {
    goBack: Function;
  };
};
type MyState = {
  loading: boolean;
};
type FormValues = {
  password?: string;
};
export default class ResetPassword extends Component<MyProps, MyState> {
  state = { loading: false };
  onFinish = async (values: FormValues) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    console.log(values.password, token);
    this.setState({ loading: true });
    try {
      await Axios.post("/api/reset_password", {
        token,
        newPassword: values.password,
      });
      message.success("Successfully changed password!");
    } catch (error) {
      showError("Failed to reset password", error);
    } finally {
      this.setState({ loading: false });
    }
  };
  render() {
    return (
      <Form
        onFinish={this.onFinish}
        className="login-form"
        style={{ maxWidth: "30em" }}
      >
        <Form.Item
          name="password"
          rules={[
            {
              min: 6,
              message: "Password should be at least 6 characters long.",
            },
            {
              required: true,
              message: "Please enter a valid password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="New Password"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              min: 6,
              message: "Password should be at least 6 characters long.",
            },
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The two passwords don't match!");
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Confirm Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={this.state.loading}
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
