import { Button, message, Result, Spin } from "antd";
import React from "react";
import { useEffect, useState } from "react";

type CheckoutResult = "success" | "canceled" | undefined;
export const SubscriptionCheckoutResult = () => {
  const [result, setResult] = useState<CheckoutResult>();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    switch (urlParams.get("result")) {
      case "success":
        setResult("success");
        break;
      case "canceled":
        setResult("canceled");
        break;
      default:
        message.error("unknown error");
        break;
    }
  }, []);

  if (!result) return <Spin />;
  if (result === "canceled")
    return (
      <Result
        status="warning"
        title="Subscription Canceled"
        subTitle="Your subscription purchase was canceled."
        extra={
          <a href="/purchase-subscription">
            <Button>Try again</Button>
          </a>
        }
      />
    );
  return (
    <Result
      status="success"
      title="Subscription successfully purchased"
      subTitle="Your subscription purchase was successful."
      extra={
        <a href="/account">
          <Button>Back to Account</Button>
        </a>
      }
    />
  );
};
