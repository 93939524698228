import React, { Component } from "react";
import { Session } from "../pages/Dashboard";
import StackedBarChart from "./charts/StackedBarChart";

type Props = {
  sessions: Session[];
  loading: boolean;
};
type State = {};
export default class GamesPlayedChart extends Component<Props, State> {
  processData = () => {
    const gamesPlayed: { [gameName: string]: { [timestamp: string]: number } } =
      {};
    this.props.sessions.forEach((session) => {
      const date =
        session.timestamp.split("-")[0] +
        "-" +
        parseInt(session.timestamp.split("-")[1]);
      gamesPlayed[session.game] = gamesPlayed[session.game] || {};
      gamesPlayed[session.game][date] = gamesPlayed[session.game][date] || 0;
      gamesPlayed[session.game][date]++;
    });
    const chartData: { [gameName: string]: { x: number; y: number }[] } = {};
    for (const [gameName, value] of Object.entries(gamesPlayed)) {
      for (const [date, gameCount] of Object.entries(value)) {
        chartData[gameName] = chartData[gameName] || [];
        chartData[gameName].push({ x: new Date(date).getTime(), y: gameCount });
      }
    }
    return chartData;
  };
  render() {
    return (
      <StackedBarChart loading={this.props.loading} data={this.processData()} />
    );
  }
}
