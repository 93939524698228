import { loadStripe } from "@stripe/stripe-js";
import { Button, Card, Typography } from "antd";
import React from "react";
import Api from "../../api";
import { userStore } from "../../stores/UserStore";
import { getUserId, showError } from "../../utils";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
const { Title } = Typography;

export const Subscription = () => {
  const { userInfo } = userStore;
  if (!userInfo || userInfo.licenseType !== "license") return null;
  const redirectToStripePortal = async () => {
    const stripe = await stripePromise;
    if (!stripe) throw new Error("Failed to load stripe");
    const session = await Api.post(
      "/api/payments/create-customer-portal-session",
      { user_id: getUserId() }
    );
    // When the customer clicks on the button, redirect them to Checkout.
    window.location.href = session.url;
  };
  const renderRenewalDate = () => {
    if (userInfo.current_period_end) {
      return (
        <>
          <h3>Subscription renewal date:</h3>
          {new Date(userInfo.current_period_end).toLocaleDateString()}
          <br />
          <Button onClick={redirectToStripePortal}>
            Manage My Subscription
          </Button>
        </>
      );
    } else {
      return (
        <a href="/purchase-subscription">
          <Button size="large" type="primary">
            Purchase Monthly License
          </Button>
        </a>
      );
    }
  };

  return (
    <Card>
      <Title>Subscription</Title>
      <h3>Subscription valid until:</h3>
      {new Date(userInfo.licenseExpiryDate).toLocaleDateString()}
      <br />
      <br />
      {renderRenewalDate()}
    </Card>
  );
};
