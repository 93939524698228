import { Form, Input, Select } from "antd";
import React, { useState } from "react";
import { getCode, getName, getNames } from "country-list";
type Props = {
  initialValues?: {
    country_code: string;
  };
  onChangeCountry: (countryCode: string | undefined) => void;
};
export const CustomerAddressForm = (props: Props) => {
  return (
    <>
      <Form.Item
        label="Country"
        name="country_code"
        rules={[{ required: true, message: "Please select a country!" }]}
      >
        <CountryInput
          onChange={props.onChangeCountry}
          initialValue={props.initialValues?.country_code}
        />
      </Form.Item>

      <Form.Item
        label="Address line 1"
        name="address_line_1"
        rules={[
          {
            required: true,
            message: "Please enter the first line of your address",
          },
        ]}
      >
        <Input autoComplete="address-line1" />
      </Form.Item>
      <Form.Item label="Address line 2" name="address_line_2">
        <Input autoComplete="address-line2" />
      </Form.Item>
      <Form.Item
        label="Zip/ postal code"
        name="postal_code"
        rules={[{ required: true, message: "Please enter a postal code!" }]}
      >
        <Input autoComplete="postal-code" />
      </Form.Item>
      <Form.Item
        label="City"
        name="city"
        rules={[{ required: true, message: "Please enter a city!" }]}
      >
        <Input autoComplete="address-level2" />
      </Form.Item>
      <Form.Item label="State/Province" name="state">
        <Input />
      </Form.Item>
    </>
  );
};

const CountryInput = (props: {
  initialValue?: string;
  onChange: (country_code: string | undefined) => void;
}) => {
  const [country, setCountry] = useState<string>();
  const onChange = (value: string) => {
    if (props.onChange) {
      if (value) {
        const code = getCode(value);

        setCountry(value);

        props.onChange(code);
      } else {
        props.onChange(undefined);
      }
    }
  };
  const onAutoFill = (e: any) => {
    const countryName = getName(e.target.value);
    setCountry(countryName);
    if (props.onChange) props.onChange(e.target.value);
  };
  return (
    <div style={{ maxWidth: 500 }}>
      <Input
        className="hidden"
        style={{
          display: "hidden", // use a hidden input to autofill country
          width: 0,
          height: 0,
          padding: 0,
          margin: 0,
          border: "none",
        }}
        autoComplete="country"
        onChange={onAutoFill}
      />

      <Select
        {...{ autoComplete: "none" }}
        defaultValue={props.initialValue && getName(props.initialValue)}
        value={country}
        showSearch
        onChange={onChange}
      >
        {getNames().map((country) => (
          <Select.Option key={country}>{country}</Select.Option>
        ))}
      </Select>
    </div>
  );
};
