import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import Axios from "../api";
import * as Sentry from "@sentry/react";
import { showError } from "../utils";

type MyProps = {
  history: {
    goBack: Function;
  };
};
type MyState = {
  loading: boolean;
  hasSubmitted: boolean;
};
type FormValues = {
  email?: string;
};
export default class ForgotPassword extends Component<MyProps, MyState> {
  state = { loading: false, hasSubmitted: false };
  onFinish = async (values: FormValues) => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    try {
      await Axios.post("/api/forgot_password", { email: values.email });
      message.success(
        "Successfully requested password reset. Please check your email!"
      );
    } catch (error) {
      showError("Failed to request password reset", error);
    } finally {
      this.setState({ loading: false, hasSubmitted: true });
    }
  };
  render() {
    return (
      <Form
        onFinish={this.onFinish}
        className="login-form"
        style={{ maxWidth: "30em" }}
      >
        <Form.Item
          hasFeedback
          name="email"
          rules={[
            { type: "email", message: "Invalid email address" },
            { required: true, message: "Please enter your email address!" },
          ]}
        >
          <Input
            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={this.state.hasSubmitted}
            loading={this.state.loading}
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {this.state.hasSubmitted
              ? "Password Reset Submitted"
              : "Request Password Change"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
