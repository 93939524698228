import { Spin, Table, Typography } from "antd";

import React, { useEffect, useState } from "react";
import Api from "../api";
import { getUserId, months, showError } from "../utils";
const { Title } = Typography;
type Stats = {
  total_spent: string;
  month: number;
  year: number;
};
type ReferredUser = {
  total_spent: string;
  email: string;
  games_played: number;
};
export const AffiliateStats = () => {
  const [stats, setStats] = useState<Stats[]>();
  const [referredUsers, setReferredUsers] = useState<ReferredUser[]>();
  const [payoutRate, setPayoutRate] = useState<number>(0);
  const [payoutType, setPayoutType] = useState<"credits" | "cash">("credits");
  useEffect(() => {
    const fetchStats = async () => {
      const userId = getUserId();
      try {
        const res = await Api.get(`/api/users/${userId}/affiliate_stats`);
        setStats(res.stats);
        setReferredUsers(res.referred_users);
        setPayoutRate(res.affiliate_payout_rate);
        setPayoutType(res.affiliate_payout_type);
      } catch (error) {
        showError("Failed to get stats", error);
      }
    };
    fetchStats();
  }, []);
  if (!stats) return <Spin />;
  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },

    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      render: (month: number) => months[month - 1],
    },
    {
      title: "€ spent",
      dataIndex: "total_spent",
      key: "total_spent",
      render: (x: number) => "€" + x / 100 || 0,
    },
    {
      title: "Rate",
      key: "rate",
      render: () => payoutRate + "%",
    },
    {
      title: "Expected payout",
      key: "payout",
      render: (x: Stats) => {
        const value =
          Math.round((((+x.total_spent / 100) * payoutRate) / 100) * 10) / 10;
        const prefix = payoutType === "cash" ? "€" : "";
        const suffix = payoutType === "cash" ? "" : " credits";

        return prefix + value + suffix;
      },
    },
  ];
  return (
    <div>
      <Title>Monthly Stats</Title>
      <Table dataSource={stats} columns={columns} />
      <Title>Referred Users</Title>
      <Table dataSource={referredUsers} columns={userColumns} />
    </div>
  );
};

const userColumns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "€ spent",
    dataIndex: "total_spent",
    key: "total_spent",
    render: (x: number) => "€" + x / 100 || 0,
  },
  {
    title: "Games Played",
    dataIndex: "games_played",
    key: "games_played",
  },
];
