import React, { Component } from "react";
import { Empty, Spin } from "antd";
import {
  VictoryChart,
  VictoryArea,
  VictoryTheme,
  VictoryAxis,
  VictoryTooltip,
  InterpolationPropType,
  DomainTuple,
  createContainer,
  VictoryZoomContainerProps,
  VictoryVoronoiContainerProps,
  DomainPropType,
} from "victory";

import maxBy from "lodash/maxBy";

type Props = {
  data: Data[] | undefined;
  useHours?: boolean;
  maxPoints?: number;
  tickValues?: number[] | string[];
  allowZoom?: boolean;
  interpolation?: InterpolationPropType;
  fixLabelOverlap?: boolean;
  loading: boolean;
};
type State = {
  zoomedXDomain: [number, number];
};
type Data = {
  x: number;
  y: number;
};
type Domain = {
  x?: [number, number];
  y?: [number, number];
};
export default class AreaChart extends Component<Props, State> {
  entireDomain: Domain = {};
  constructor(props: Props) {
    super(props);
    if (!props.data || !props.data.length) return;
    this.entireDomain = this.getEntireDomain(props.data);
    this.state = {
      zoomedXDomain: this.entireDomain.x || [0, 1],
    };
  }
  componentDidMount() {}
  getEntireDomain(data: Data[]): Domain {
    const xStart = data[0].x;
    const xEnd = data.length > 1 ? data[data.length - 1].x : xStart + 1;
    return {
      y: [0, (maxBy(data, ({ y }: Data) => y) || { y: 1 }).y * 1.2],
      x: [xStart, xEnd],
    };
  }
  onDomainChange = (
    domain: DomainPropType,
    props: VictoryZoomContainerProps
  ) => {
    this.setState({
      zoomedXDomain: (
        domain as {
          x: DomainTuple;
        }
      ).x as [number, number],
    });
  };

  getData = () => {
    if (!this.props.data) return undefined;

    const padding = 86400000 * 10;
    const { zoomedXDomain } = this.state;
    const { data, maxPoints = 100 } = this.props;

    const startIndex = data.findIndex((d) => d.x >= zoomedXDomain[0] - padding);
    let endIndex = data.findIndex((d) => d.x > zoomedXDomain[1] + padding);
    if (endIndex === -1) endIndex = data.length;
    const filtered = data.slice(startIndex, endIndex);

    if (filtered.length > maxPoints) {
      // limit k to powers of 2, e.g. 64, 128, 256
      // so that the same points will be chosen reliably, reducing flicker
      const k = Math.pow(2, Math.ceil(Math.log2(filtered.length / maxPoints)));
      return filtered.filter(
        // ensure modulo is always calculated from same reference: i + startIndex
        (d, i) => (i + startIndex) % k === 0
      );
    }
    return filtered;
  };

  getZoomLevel = () => {
    const domain = this.state.zoomedXDomain;
    return (domain[1] - domain[0]) / 8.64e7;
  };
  formatTick = (timestamp: number) => {
    if (this.props.tickValues) return timestamp;

    const date = new Date(timestamp);
    const zoomLevel = this.getZoomLevel();
    const year = date.getFullYear().toString().slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    if (zoomLevel > 40) {
      return month + "/" + year;
    }
    return date.getDate() + "/" + month;
  };

  render() {
    const axisStyle = {
      axis: { stroke: "#ffffff0f" },
      grid: { stroke: "#ffffff0f", strokeDasharray: "none" },
      ticks: { stroke: "#ffffff0f", display: "none" },

      tickLabels: { fontSize: 5, padding: 3, fill: "#5a717c" },
    };
    const VictoryZoomVoronoiContainer = createContainer<
      VictoryZoomContainerProps,
      VictoryVoronoiContainerProps
    >("zoom", "voronoi");
    if (this.props.loading) return <Spin />;

    if (!this.props.data || !this.props.data.length)
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <VictoryChart
          padding={14}
          height={150}
          domain={this.entireDomain as { x: DomainTuple; y: DomainTuple }}
          // domain={{ x: [0, 1], y: [0, 1] }}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryZoomVoronoiContainer
              disable={!this.props.allowZoom}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={1}
                  flyoutStyle={{
                    stroke: "black",
                    strokeWidth: 0.1,
                    fill: "rgb(37 41 49)",
                  }}
                  style={{ fontSize: 5, fill: "#b1b1b1" }}
                />
              }
              labels={({ datum }) =>
                `${new Date(datum.x).toDateString()}, ${datum.y} games played`
              }
              allowZoom={this.props.allowZoom ?? true}
              minimumZoom={{ x: 8.64e7 * 4, y: 1 }}
              zoomDimension="x"
              onZoomDomainChange={this.onDomainChange}
            />
          }
        >
          <VictoryAxis
            style={axisStyle}
            fixLabelOverlap={this.props.fixLabelOverlap}
            tickFormat={this.formatTick}
            tickValues={this.props.tickValues || []}
          />

          <VictoryAxis dependentAxis tickFormat={(y) => y} style={axisStyle} />
          <VictoryArea
            interpolation={this.props.interpolation || "natural"}
            style={{
              data: {
                stroke: "url(#strokeGradient)",
                fill: "url(#fillGradient)",
                strokeWidth: 2,
              },
              parent: { border: "1px solid #ccc" },
            }}
            data={this.getData()}
          />
        </VictoryChart>
      </div>
    );
  }
}
