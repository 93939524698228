import React, { Component, Ref } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Tooltip, message, Result, Typography } from "antd";

import Axios from "../../api";
import { getUserId, showError } from "../../utils";
import * as Sentry from "@sentry/react";
import { BillingInformationForm } from "../account/BillingInformationForm";
import { userStore } from "../../stores/UserStore";
const { Title } = Typography;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
type Props = {};
type State = {
  creditQuantity: number;
  isLoading: boolean;
  wasSuccessful: boolean;
  needsToUpdateAddress: boolean;
};
type CreditsVideo = "creditsVideo0" | "creditsVideo1" | "creditsVideo2";
const CREDIT_OPTIONS = [
  // { value: 100, bonus: 0 },
  { value: 250, bonus: 0, color1: "#4bcdff", color2: "#9e3cff" },
  { value: 500, bonus: 50, color1: "#883eff", color2: "#ff61d9" },
  { value: 1000, bonus: 250, color1: "#ff449e", color2: "#fc8930" },
];
export default class PurchaseCredits extends Component<Props, State> {
  creditsVideo0 = React.createRef<HTMLVideoElement>();
  creditsVideo1 = React.createRef<HTMLVideoElement>();
  creditsVideo2 = React.createRef<HTMLVideoElement>();

  constructor(props: Props) {
    super(props);
  }
  state = {
    creditQuantity: CREDIT_OPTIONS[1].value,
    isLoading: false,
    wasSuccessful: false,
    needsToUpdateAddress: false,
  };

  componentDidMount() {
    this.checkIfAddressIsSet();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("canceled")) message.warning("Purchase canceled ");
    if (urlParams.get("success")) {
      message.success("Purchase successful!");
      this.setState({ wasSuccessful: true });
    }
  }

  checkIfAddressIsSet = async () => {
    try {
      const userId = getUserId();
      const values = await Axios.get(`/api/users/${userId}/billing_info`);
      this.setState({ needsToUpdateAddress: !values.address_line_1 }); //don't check every variable because if one of them is set, all the required variables should be set.
    } catch (error) {
      showError("Failed to get current billing information", error);
    }
  };
  changeOption = (value: number, index: number) => {
    this.setState({ creditQuantity: value });
    console.log(index);
    const video = this[`creditsVideo${index}` as CreditsVideo]?.current;
    if (video) {
      video.currentTime = 0;
      video.play();
    }
  };

  renderCreditsOptions = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          // maxWidth: 1264,
        }}
      >
        {CREDIT_OPTIONS.map(({ value, bonus, color1, color2 }, index) => {
          const isSelected = this.state.creditQuantity === value;
          return (
            <div
              key={value}
              style={{
                cursor: "pointer",
                // background: `linear-gradient(45deg, ${color1} , ${color2} )`,
                // background:
                //   "linear-gradient(20deg, rgb(147 62 255) 1%, rgb(82 237 255) 100%)",
                background: "dark-grey",
                height: 500,
                marginRight: "3em",
                marginBottom: 10,
                boxSizing: "border-box",
              }}
              onClick={() => this.changeOption(value, index)}
            >
              <video
                ref={this[`creditsVideo${index}` as CreditsVideo]}
                autoPlay
                // alt={value + ""}
                style={{
                  margin: 0,
                  height: 300,
                  width: 300,
                  borderRadius: "1em",
                  boxShadow: isSelected ? `0 0 0 4px ${color2}` : "none",
                  background: `linear-gradient(45deg, ${color1} , ${color2} )`,
                }}
                src={`/images/Coins${index + 1}.webm`}
                // src={`/images/credits_${index + 2}.png`}
              />
              <div
                style={{
                  background: "#444444a6",
                  marginTop: "2em",
                  borderRadius: "1em",
                  boxShadow: isSelected ? `0 0 0 4px ${color2}` : "none",
                }}
              >
                {bonus > 0 ? (
                  <div
                    style={{
                      // background: "rgb(0 0 0 / 14%)",
                      color: "white",
                      height: 80,
                      fontSize: 30,
                      fontWeight: 500,
                      textAlign: "center",
                      lineHeight: `${80}px`,
                    }}
                  >
                    {value + " + "}{" "}
                    <span
                      style={{
                        background: `linear-gradient(128deg, ${color1} 0%, ${color2} 100%)`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      <b>{bonus} Bonus</b>
                    </span>
                  </div>
                ) : (
                  <div style={{ height: 80 }} />
                )}
                <div
                  style={{
                    marginBottom: 20,
                    // background: "hsl(216deg 100% 50% / 65%)",
                    // height: 80,
                    padding: 10,
                    textAlign: "center",
                    lineHeight: "30px",
                  }}
                >
                  <span
                    style={{ color: "#fff", fontSize: 30, fontWeight: 500 }}
                  >
                    {value + bonus} Credits
                  </span>
                  <br />
                  <span
                    style={{
                      color: color1,
                      // background: `linear-gradient(128deg, ${color1} 0%, ${color2} 100%)`,
                      // WebkitBackgroundClip: "text",
                      // WebkitTextFillColor: "transparent",
                      fontSize: 30,
                      // fontWeight: 500,
                    }}
                  >
                    <b>€{value}</b>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  renderUpdateAddress = () => {
    if (this.state.needsToUpdateAddress)
      return (
        <>
          <Result
            status="warning"
            title="Update Billing Information"
            subTitle="Please update your billing information before purchasing credits."
          />
          <BillingInformationForm onSuccess={this.checkIfAddressIsSet} />
        </>
      );
  };
  handleClickCheckout = async () => {
    this.setState({ isLoading: true });
    try {
      const stripe = await stripePromise;
      if (!stripe) return console.error("stripe == null");
      const user_id = getUserId();
      const response = await Axios.post("/api/payments/create-session", {
        creditQuantity: this.state.creditQuantity,
        user_id,
      });
      const session = await response;
      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        throw result.error;

        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } catch (error) {
      showError("Failed to create checkout session", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    if (userStore.userInfo && userStore.userInfo?.licenseType !== "credits") {
      return (
        <Result
          status="error"
          title="Invalid license type"
          subTitle="This account cannot buy credits."
          extra={
            <a href="/account">
              <Button>Go back to account overview</Button>
            </a>
          }
        />
      );
    }
    if (this.state.wasSuccessful)
      return (
        <Result
          status="success"
          title="Successfully Purchased credits!"
          subTitle="You will receive an email confirmation."
        />
      );

    return (
      <div
      // style={{
      //   width: "100%",
      // }}
      >
        <Title>Top Up Your Credits</Title>
        <br />
        <br />
        {this.renderUpdateAddress()}
        {this.renderCreditsOptions()}
        <Tooltip
          title={`Purchase ${this.state.creditQuantity} credits. You will be redirected to Stripe to complete your purchase.`}
        >
          <Button
            style={{
              marginTop: 100,
              marginRight: 16,
              width: 200,
              float: "right",
            }}
            disabled={this.state.needsToUpdateAddress}
            loading={this.state.isLoading}
            onClick={this.handleClickCheckout}
            type="primary"
            size="large"
          >
            Checkout
          </Button>
        </Tooltip>
      </div>
    );
  }
}
