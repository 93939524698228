import React from "react";
type Props = {
  children: any;
  title?: string;
  style?: {};
};
const Card = (props: Props) => {
  return (
    <div className="card" style={{ ...props.style }}>
      {props.title && <h2 style={{ color: "#999", fontWeight: 300 }}>{props.title}</h2>}
      <div style={{ fontSize: 24 }}>{props.children}</div>
    </div>
  );
};

export default Card;
