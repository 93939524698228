import React, { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Form, Input, message, Spin, Typography } from "antd";
import { checkVAT, countries } from "jsvat";
import { CustomerAddressForm } from "./CustomerAddressForm";
import { getUserId, showError } from "../../utils";
import Api from "../../api";
const { Title } = Typography;

type Props = {
  onSuccess?: () => void;
};
export const BillingInformationForm = (props: Props) => {
  const [defaults, setDefaults] = useState();
  const [countryCode, setCountryCode] = useState<string>();
  const [disableVAT, setDisableVAT] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    const getBillingInfo = async () => {
      try {
        const userId = getUserId();
        const values = await Api.get(`/api/users/${userId}/billing_info`);
        setDefaults(values);
        setCountryCode(values.country_code);
      } catch (error) {
        showError("Failed to get current billing information", error);
      }
    };

    getBillingInfo();
  }, []);

  const updateBillingInfo = async (values: any) => {
    const userId = getUserId();
    setIsUpdating(true);
    if (disableVAT) values.vat_number = "";
    try {
      await Api.patch(`/api/users/${userId}`, values);
      message.success("Updated billing information!");
      if (props.onSuccess) props.onSuccess();
    } catch (error) {
      showError("Failed to update billing info", error);
    }
    setIsUpdating(false);
  };
  const isInEU = useMemo(() => isCountryInEU(countryCode), [countryCode]);
  if (!defaults) return <Spin />;
  const onChangeCountry = (countryCode: string | undefined) => {
    setCountryCode(countryCode);
  };
  return (
    <Form
      initialValues={defaults}
      name="billing"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={updateBillingInfo}
      // onFinishFailed={onFinishFailed}
    >
      <Title>Billing Information</Title>
      <Title level={4}>Company</Title>
      <Form.Item
        label="Company Name"
        name="company_name"
        rules={[
          {
            required: true,
            message: "Please enter your company name",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="VAT number (required for EU customers. If not applicable, enter 'N/A')"
        name="vat_number"
        rules={[
          {
            required: isInEU && !disableVAT,
            message: "VAT is required in EU",
          },
          {
            // message: "Please enter a valid VAT number",
            validator: async (_, value) => {
              if (!isInEU || disableVAT) return Promise.resolve();
              if (!isValidVATNumber(value))
                return Promise.reject("Please enter a valid VAT number");
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input disabled={disableVAT} />
      </Form.Item>
      {isInEU && (
        <Form.Item
          label={
            <span>
              I don't have a valid VAT number{" "}
              <b>(you will be charged 21% VAT)</b>
            </span>
          }
        >
          <Checkbox
            checked={disableVAT}
            onChange={(e) => setDisableVAT(e.target.checked)}
          />
        </Form.Item>
      )}
      <Form.Item label="Company Website" name="company_website">
        <Input />
      </Form.Item>
      <br />
      <br />
      <Title level={4}>Address</Title>
      <CustomerAddressForm
        onChangeCountry={onChangeCountry}
        initialValues={defaults}
      />
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button loading={isUpdating} type="primary" htmlType="submit">
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};
function isCountryInEU(countryCode: string | undefined): boolean {
  if (!countryCode) return false;
  const euCountryCodes = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GR",
    "HR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK",
    "SE",
    "IE",
  ];
  return euCountryCodes.includes(countryCode.toUpperCase());
}
function isValidVATNumber(vatNumber: string): boolean {
  return checkVAT(vatNumber, countries).isValid;
}
