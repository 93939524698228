import { Typography } from "antd";
import React from "react";

const { Title } = Typography;

const gear = [
  {
    title: "Router",

    items: [
      {
        url: "https://www.amazon.com/WiFi-6-Router-Gigabit-Wireless/dp/B08H8ZLKKK/ref=sr_1_3?",
        name: "TP-Link AX1800 WiFi 6 Router (Archer AX21)",
        image: "/images/gear/TPLinkAX1800.jpg",
      },
    ],
  },
  {
    title: "PC/Laptop",
    items: [
      {
        url: "https://www.amazon.com/Octa-Core-GeForce-Display-Backlit-AN17-41-R8N5/dp/B0BZST2MBK/",
        name: "Acer Nitro 17 Gaming Laptop | AMD Ryzen 7 7735HS Octa-Core CPU | NVIDIA GeForce RTX 4050 GPU",
        image: "/images/gear/GamingLaptop.jpg",
      },
    ],
  },
  {
    title: "Accessories",
    items: [
      {
        url: "https://vrcover.com/item/fitness-facial-interface-and-foam-set-for-oculus-quest-2/",
        name: "VR Cover Fitness Facial Interface ",
        image: "/images/gear/vrCover.jpg",
      },
      {
        url: "https://www.bhaptics.com/tactsuit/tactsuit-x40",
        name: "bHaptics TactSuit X40",
        image: "/images/gear/bhaptics.jpg",
      },
    ],
  },
];
const imageStyle = {
  width: 300,
};
export const RecommendedGear = () => {
  return (
    <div>
      <Title level={2}> Recommended Gear</Title>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2,1fr)",
          //   gridTemplateRows: "auto",
        }}
      >
        {gear.map(({ title, items }) => (
          <div style={{ display: "flex", flexDirection: "column" }} key={title}>
            <Title level={3}>{title}</Title>
            {items.map(({ url, name, image }) => (
              <a key={name} href={url} target="_blank" rel="noreferrer">
                <img src={image} style={imageStyle} />
                <br />
                <br />
                {name}
              </a>
            ))}
            <br />
            <br />
          </div>
        ))}{" "}
      </div>
    </div>
  );
};
