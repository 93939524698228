import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { ImageData } from "./Marketing";
import { MAX_ROW_WIDTH, TARGET_ROW_HEIGHT } from "./MarketingImageGrid";

type ImageCardProps = {
  data: ImageData;
};

export const ImageCard = ({ data }: ImageCardProps) => {
  const { imageUrl, altText } = data;
  const [isHovered, setIsHovered] = useState(false);

  const imageContainerStyles = {
    position: "relative" as "relative",
    height: data.height,
    maxHeight: TARGET_ROW_HEIGHT,
    width: data.width,
    overflow: "hidden",
    cursor: "pointer" as "pointer",
  };

  const imgStyles = {
    width: "100%",
    height: "100%",
    objectFit: "contain" as "contain",
  };

  const overlayStyles = {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "radial-gradient(circle, rgba(0,0,0,0) 30%, rgba(0,0,0,0.75) 100%)",
    opacity: isHovered ? 1 : 0,
    transition: "opacity 0.3s ease-in-out",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const iconStyles = {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "50px",
  };

  return (
    <a
      style={imageContainerStyles}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      download
      href={imageUrl}
    >
      <img
        src={`${imageUrl}?width=${MAX_ROW_WIDTH}&height=${TARGET_ROW_HEIGHT}`}
        alt={altText}
        style={imgStyles}
      />
      <div style={overlayStyles}>
        <div style={iconStyles}>
          <DownloadOutlined />
        </div>
      </div>
    </a>
  );
};
