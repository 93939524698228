import React, { useMemo } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  List,
  Menu,
  message,
  Modal,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import Api from "../api";
import { showError } from "../utils";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownOutlined,
} from "@ant-design/icons";
type FeedbackItem = {
  id: number;
  title: string;
  description: string;
  status: "default" | "started" | "done" | "planned";
  created_on: string;
  vote_count: string | null;
  user_vote: "0" | "-1" | "1" | null;
};
export const Feedback = () => {
  const [feedback, setFeedback] = useState<FeedbackItem[]>();
  const [showDone, setShowDone] = useState(false);
  const [sortBy, setSortBy] = useState("New");
  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const res = await Api.get("/api/feedback");
        setFeedback(res);
      } catch (error) {
        showError("Failed to get feedback", error);
      }
    };
    fetchFeedback();
  }, []);
  if (!feedback) return <Spin />;
  const refreshFeedbackRow = async (id: number) => {
    try {
      const row = await Api.get(`/api/feedback/${id}`);
      const newFeedback = feedback.map((x) => {
        if (x.id === id) return row;
        return x;
      });
      setFeedback(newFeedback);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeSortBy = (newSortBy: "New" | "Top") => {
    setSortBy(newSortBy);
  };
  const getSortedFeedback = () => {
    let res = [...feedback];
    switch (sortBy) {
      case "New":
        res.sort(
          (a, b) =>
            new Date(b.created_on).getTime() - new Date(a.created_on).getTime()
        );
        break;
      case "Top":
        res.sort((a, b) => +(b.vote_count || 0) - +(a.vote_count || 0));
        break;
    }
    return res;
  };
  const getFeedback = () => {
    return getSortedFeedback().filter((x) => showDone || x.status !== "done");
  };
  return (
    <div style={{ maxWidth: "50%" }}>
      <FeedbackSortButton onChange={onChangeSortBy} />
      <CreateFeedbackItemModal />
      <br />
      <br />
      <Checkbox onChange={() => setShowDone(!showDone)} value={showDone}>
        Show implemented ideas
      </Checkbox>
      <List>
        {getFeedback().map((feedbackItem) => (
          <FeedbackItemRow
            {...feedbackItem}
            key={feedbackItem.id}
            onVote={() => {
              refreshFeedbackRow(feedbackItem.id);
            }}
          />
        ))}
      </List>
    </div>
  );
};

const FeedbackItemRow = (props: FeedbackItem & { onVote: () => void }) => {
  const onVote = async (delta: number) => {
    try {
      await Api.post(`/api/feedback/${props.id}/vote`, { delta });
      props.onVote();
    } catch (error) {
      showError("Failed to vote on idea", error);
    }
  };
  return (
    <List.Item style={{ justifyContent: "start" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
          margin: 12,
        }}
      >
        <Tooltip title={"I like this idea"}>
          <Button
            size="small"
            onClick={() => onVote(props.user_vote === "1" ? 0 : 1)}
            style={{
              background: props.user_vote === "1" ? "#c271ff" : "inherit",
            }}
          >
            <CaretUpOutlined />
          </Button>
        </Tooltip>
        <span style={{ textAlign: "center" }}>{props.vote_count || 0}</span>

        <Tooltip title={"I don't like this idea"}>
          <Button
            size="small"
            onClick={() => onVote(props.user_vote === "-1" ? 0 : -1)}
            style={{
              background: props.user_vote === "-1" ? "#c271ff" : "inherit",
            }}
          >
            <CaretDownOutlined />
          </Button>
        </Tooltip>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h3>{props.title}</h3>
        <small>
          Added on {new Date(props.created_on).toLocaleDateString()}
        </small>
        <FeedbackItemDescription description={props.description} />
        <br />
        {props.status === "started" && (
          <Tag style={{ width: "fit-content" }} color="orange">
            In Progress
          </Tag>
        )}
        {props.status === "done" && (
          <Tag style={{ width: "fit-content" }} color="green">
            Done
          </Tag>
        )}
        {props.status === "planned" && (
          <Tag style={{ width: "fit-content" }} color="cyan">
            Planned
          </Tag>
        )}
      </div>
    </List.Item>
  );
};
const FeedbackItemDescription = (props: { description: string }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const maxLength = 300;
  if (props.description.length <= maxLength || showFullDescription)
    return <span>{props.description}</span>;

  return (
    <span>
      {props.description.substr(0, maxLength)}{" "}
      <Button size="small" onClick={() => setShowFullDescription(true)}>
        Read More...
      </Button>
    </span>
  );
};
const CreateFeedbackItemModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = async (values: any) => {
    setIsSubmitting(true);
    try {
      await Api.post("/api/feedback", values);
      message.success("Successfully submitted idea!");
      setShowModal(false);
      setTimeout(() => {
        window.location.href = "/feedback";
      }, 1000);
    } catch (error) {
      showError("Failed to submit idea", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setShowModal(true)}>
        Submit your own idea
      </Button>
      <Modal
        footer={null}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
      >
        <Form onFinish={onFinish}>
          <Form.Item
            name="title"
            rules={[
              { required: true, message: "Please enter a title" },
              { max: 48, message: "Exceeded maximum length" },
            ]}
          >
            <Input placeholder="Give your idea a title" size="large" />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[
              { required: true, message: "Please enter a description" },

              { max: 1500, message: "Exceeded maximum length" },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Describe your idea"
              size="large"
            />
          </Form.Item>

          <Button
            loading={isSubmitting}
            disabled={isSubmitting}
            size="large"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const FeedbackSortButton = (props: {
  onChange: (sortBy: "New" | "Top") => void;
}) => {
  const [sortBy, _setSortBy] = useState<"New" | "Top">("New");
  const setSortBy = (value: any) => {
    _setSortBy(value);
  };
  useEffect(() => {
    props.onChange(sortBy);
  }, [sortBy]);
  const menu = (
    <Menu selectable selectedKeys={[sortBy]} onSelect={(x) => setSortBy(x.key)}>
      <Menu.Item key="New">New</Menu.Item>
      <Menu.Item key="Top">Top</Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Dropdown overlay={menu}>
        <Typography.Link style={{ fontSize: 20 }}>
          <Space>
            {sortBy}
            <DownOutlined />
          </Space>
        </Typography.Link>
      </Dropdown>
    </div>
  );
};
