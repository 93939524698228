import { Result, Spin } from "antd";
import React, { useEffect, useState } from "react";

import Axios from "../api";
import { showError } from "../utils";

export const ValidateLoginCode = () => {
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState("");

  useEffect(() => {
    const validateCode = async () => {
      try {
        //get code from url params
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");

        await Axios.get(`/api/login_code/${code}/validate`);
        setStatus("success");
      } catch (error: any) {
        showError("Failed to validate code", error);
        setError(error?.message);
        setStatus("error");
      }
    };
    validateCode();
  }, []);

  if (status === "loading") return <Spin />;
  if (status === "success")
    return (
      <Result
        title="Successfully signed in!"
        subTitle="You should now be signed in to the launcher."
        status={"success"}
      ></Result>
    );
  return (
    <Result
      title="Failed to validate code"
      subTitle={error}
      status={"error"}
    ></Result>
  );
};
