import React from "react";
import { Button, Card, Table, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import Api from "../../api";
import { userStore } from "../../stores/UserStore";
import { showError } from "../../utils";
import { DownloadOutlined } from "@ant-design/icons";
const { Title } = Typography;
type OrderInfo = {
  order_id: number;
  invoice_number: number;
  amount_charged: number;
  date_paid: string;
};

export const OrderOverview = () => {
  const [orders, setOrders] = useState<OrderInfo[]>([]);
  const [loadingOrders, setLoadingOrders] = useState<number[]>([]);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const _orders: any = await Api.get(
          `/api/users/${userStore.userInfo?.user_id}/orders`
        );
        setOrders(_orders);
      } catch (error) {
        showError("Failed to get user orders", error);
      }
    };

    fetchOrders();
  }, []);

  const columns = [
    {
      title: "Date",
      key: "timestamp",
      render: (x: OrderInfo) => new Date(x.date_paid).toLocaleDateString(),
    },

    {
      title: "Amount Charged",
      key: "price_paid",
      render: (x: OrderInfo) => "€" + x.amount_charged / 100, //is stored as an integer so 1000 = 10.00 euros
    },
    {
      title: "",
      key: "download",
      render: (x: OrderInfo) => downloadInvoiceButton(x),
    },
  ];
  const downloadInvoiceButton = (orderInfo: OrderInfo) => {
    const loading = loadingOrders.includes(orderInfo.order_id);
    return (
      <Tooltip title="Download Invoice">
        <Button
          style={{ width: 46, height: 32 }}
          loading={loading}
          disabled={loading}
          onClick={async () => {
            setLoadingOrders([...loadingOrders, orderInfo.order_id]);
            await downloadInvoice(orderInfo);
            setLoadingOrders(
              loadingOrders.filter((x) => x === orderInfo.order_id)
            );
          }}
        >
          {!loading && <DownloadOutlined />}
        </Button>
      </Tooltip>
    );
  };

  return (
    <Card>
      <Title level={3}>Orders</Title>
      <Table dataSource={orders} columns={columns} />
    </Card>
  );
};

async function downloadInvoice(orderInfo: OrderInfo) {
  const { order_id, invoice_number } = orderInfo;
  try {
    const invoice = await Api.get(
      `/api/users/${userStore.userInfo?.user_id}/orders/${order_id}/invoice`,
      {},
      { responseType: "arraybuffer" }
    );
    const file = new Blob([invoice], { type: "application/pdf" });

    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `invoice_order${invoice_number}.pdf`;
    link.click();
  } catch (error) {
    showError("Failed to download order invoice", error);
  }
}
